import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as React from "react";
import { cloneElement } from "react";
import {
  ArrayField,
  AutocompleteInput,
  BulkDeleteWithConfirmButton,
  ChipField,
  CreateButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { apiUrl } from "../../../utils/utilsFetch";
import medioData from "Static/data/medio.json";

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
    <ReferenceInput
      source="type_id"
      label="Tipo"
      reference="types"
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={(searchText) => ({
        search: searchText,
      })}
      perPage={2000}
    >
      <AutocompleteInput source="name" optionText="name" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

const ExportButton = ({ filename = "archivo" }) => {
  const [loading, setLoading] = React.useState(false);
  const handleDownload = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let response = await fetch(apiUrl + "/entities/download/funcionario", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.ok) {
      setLoading(false);
      return { error: "Error al descargar el archivo" };
    }
    let blob = await response.blob();
    if (blob instanceof Blob) {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename + ".xlsx";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setLoading(false);
      return { message: "Descarga completa" };
    }
  };
  return (
    <Button
      startIcon={loading ? <CircularProgress size={18} /> : <GetAppIcon />}
      onClick={() => handleDownload()}
      color="primary"
      size="small"
      variant="outlined"
      style={{ marginRight: "8px", marginLeft: "8px" }}
      disabled={loading}
    >
      Funcionarios
    </Button>
  );
};

export const ActionsCreate = ({
  label = "Nuevo",
  resource,
  filter,
  exporter,
  maxResults = 10000,
  ...props
}) => (
  <TopToolbar>
    {props?.filters && cloneElement(props?.filters, { context: "button" })}
    {medioData?.export_funcionarios ? (
      <ExportButton filename="funcionarios" />
    ) : null}
    <CreateButton
      variant="contained"
      size="medium"
      style={{ marginLeft: "8px" }}
      label={label}
      translate={label}
    />
  </TopToolbar>
);

export const EntityList = ({ permissions, ...props }) => {
  const translate = useTranslate();

  const exporter = (values) => {};

  return (
    <List
      filters={<CategoriesFilter />}
      sort={{ field: "order", order: "ASC" }}
      bulkActionButtons={
        <BulkDeleteWithConfirmButton
          confirmTitle={`${translate("resources.entities.confirm_title_bulk")}`}
          confirmContent={""}
        />
      }
      title=" "
      actions={
        <ActionsCreate label={`${translate("resources.entities.new")}`} />
      }
      filter={{ show_all: true }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="order" />
        <TextField source="name" />
        <TextField source="slug" />
        <ReferenceField source="type_id" reference="types" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="relations" reference="relations">
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        {permissions &&
          permissions.some(
            (permission) =>
              permission.name === "*" || permission.name === "edit-entities"
          ) && <EditButton />}
      </Datagrid>
    </List>
  );
};
