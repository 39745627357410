import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import { ImageInput } from "./ImageInput";
import { ObjectRender } from "./ObjectRender";
import medioData from "Static/data/medio.json";
import { ListObjects } from "./ListObjects";
import { RelationField } from "./RelationField";

export const ArrayInputAttributes = ({ label, data, setData }) => {
  const handleItemChange = (index, fieldName, fieldValue) => {
    const updatedItems = [...data];
    updatedItems[index][fieldName] = fieldValue;
    setData(updatedItems);
  };

  function slugToText(slug, item) {
    // Reemplazar guiones o guiones bajos con espacios
    let text = slug;
    if (slug) {
      if (item?.relation_type_id != null) {
        text = text
          .replace("relation_funcionario_", "")
          .replace("relation_municipio_", "")
          .replace("relation_", "");
      }
      text = text.replace(/-/g, " ").replace(/_/g, " ");
      text = text.charAt(0).toUpperCase() + text.slice(1);

      return text;
    }
    return "";
  }

  return (
    <div>
      <Typography variant="h6">{label}</Typography>
      {data?.map((item, index) => {
        let options = item?.options;
        if (
          typeof item?.options == "string" &&
          (item?.format === "list" || item?.format === "options")
        ) {
          options = JSON.parse(options);
        }
        return (
          <div
            key={index}
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box width="300px" marginTop="10px">
              <Typography variant="body1">
                {slugToText(item?.name, item)} {item?.required ? " *" : ""}
              </Typography>
            </Box>
            {item?.format === "number" ? (
              <TextField
                id="number"
                label="Número"
                type="number"
                variant="filled"
                size="small"
                value={item?.value}
                required={item?.required}
                onChange={(e) =>
                  handleItemChange(index, "value", e.target.value)
                }
                helperText={
                  item?.required === true && !item?.value
                    ? "El campo es requerido"
                    : ""
                }
                error={item?.required === true && !item?.value}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : null}
            {item?.format === "text" ? (
              <RichTextInput
                label=""
                record={item?.value}
                source={`attributes[${index}].value`}
                variant="filled"
                toolbar={[["bold", "italic", "link"]]}
                required={item?.required}
                helperText={
                  item?.required === true && !item?.value
                    ? "El campo es requerido"
                    : ""
                }
                error={item?.required === true && !item?.value}
                onChange={(value) => {
                  handleItemChange(index, "value", value);
                }}
                fullWidth
              />
            ) : null}

            {item?.format === "link" ? (
              <TextField
                id="link"
                label="Link"
                type="text"
                variant="filled"
                size="small"
                value={item?.value}
                required={item?.required}
                onChange={(e) =>
                  handleItemChange(index, "value", e.target.value)
                }
                helperText={
                  item?.required === true && !item?.value
                    ? "El campo es requerido"
                    : ""
                }
                error={item?.required === true && !item?.value}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : null}

            {item?.format === "longtext" ? (
              <RichTextInput
                label=""
                source={`attributes[${index}].value`}
                record={item?.value}
                helperText={
                  item?.required === true && !item?.value
                    ? "El campo es requerido"
                    : ""
                }
                error={item?.required === true && !item?.value}
                variant="filled"
                toolbar={[
                  [
                    "bold",
                    "italic",
                    "link",
                    "underline",
                    "blockquote",
                    { align: [] },
                    { list: "ordered" },
                    { list: "bullet" },
                    { script: "sub" },
                    { script: "super" },
                    { indent: "-1" },
                    { indent: "+1" },
                    { direction: "rtl" },
                    "clean",
                  ],
                ]}
                required={item?.required}
                multiple
                onChange={(value) => {
                  handleItemChange(index, "value", value);
                }}
                fullWidth
              />
            ) : null}

            {item?.format === "date" ? (
              <TextField
                id="date"
                label="Fecha"
                type="date"
                variant="filled"
                size="small"
                value={item?.value}
                required={item?.required}
                helperText={
                  item?.required === true && !item?.value
                    ? "El campo es requerido"
                    : ""
                }
                error={item?.required === true && !item?.value}
                onChange={(e) =>
                  handleItemChange(index, "value", e.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : null}

            {item?.format === "image" ? (
              <ImageInput
                source={`attributes[${index}].value`}
                data={item.value}
                handleSelected={(val) => handleItemChange(index, "value", val)}
              />
            ) : null}

            {item?.format === "options" && options ? (
              <FormControl size="small">
                <InputLabel
                  id={`select-options-${index}`}
                  style={{ paddingLeft: "10px", paddingTop: "4px" }}
                  size="small"
                  error={
                    item?.required === true &&
                    (!item?.value || item?.value?.length === 0)
                  }
                >
                  Opción
                </InputLabel>
                <Select
                  labelId={`select-options-${index}`}
                  value={item.value || []}
                  multiple={true}
                  variant="filled"
                  size="small"
                  style={{ minWidth: "180px" }}
                  required={item?.required}
                  onChange={(e) =>
                    handleItemChange(index, "value", e.target.value)
                  }
                  error={
                    item?.required === true &&
                    (!item?.value || item?.value?.length === 0)
                  }
                >
                  {options?.map((i, key) => (
                    <MenuItem key={key} value={i?.name || i}>
                      {i?.name || i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={
                    item?.required === true &&
                    (!item?.value || item?.value?.length === 0)
                  }
                >
                  {item?.required === true &&
                  (!item?.value || item?.value?.length === 0)
                    ? "El campo es requerido"
                    : ""}
                </FormHelperText>
              </FormControl>
            ) : null}
            {item?.format === "list" ? (
              <ListObjects
                title={item?.name}
                new_object={options}
                value={item?.value}
                isRequired={item?.required}
                onChange={(values) => handleItemChange(index, "value", values)}
                exceptions={medioData?.exceptions || []}
              />
            ) : null}
            {item?.format === "object" ? (
              <ObjectRender
                title={item?.name}
                new_object={options}
                value={item?.value}
                isRequired={item?.required}
                onChange={(values) => handleItemChange(index, "value", values)}
              />
            ) : null}
            {item?.format === "relation" ? (
              <RelationField
                source={`attributes[${index}].value`}
                item={item}
                onChange={(value) => handleItemChange(index, "value", value)}
                isRequired={item?.required}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
